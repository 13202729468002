<template>
  <div>
    <!-- <this-header /> -->

    <div class="anan-set-nav-conttainer mt-150">
      <b-overlay
        :show="show"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div>
          <router-link :to="{name : 'new-pages'}">
            <b-button
              class="btn btn-gradient-primary mb-1 mr-50 mr-50 d-md-none"
            >
              <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
            </b-button>
          </router-link>

          <div class="VYJdTQ">
            <a
              class="OFl2GI"
              :title="$t('all')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 0 }"
              @click="GetOrderAll(status = 0)"
            >
              <span class="_20hgQK">{{ $t('all') }}</span>
            </a>

            <a
              class="OFl2GI"
              :title="$t('pendingReview')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 1 }"
              @click="GetOrderAll(status = 1)"
            >
              <span class="_20hgQK">{{ $t('awaitingDeliveryTH') }}</span>
            </a>

            <a
              class="OFl2GI"
              :title="$t('toPay')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 2 }"
              @click="GetOrderAll(status = 2)"
            >
              <span class="_20hgQK">{{ $t('awaitingPickup') }}</span>
            </a>

            <a
              class="OFl2GI"
              :title="$t('paid')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 3 }"
              @click="GetOrderAll(status = 3)"
            >
              <span class="_20hgQK">{{ $t('completed') }}</span>
            </a>

            <a
              class="OFl2GI"
              :title="$t('cancel')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 4 }"
              @click="GetOrderAll(status = 4)"
            >
              <span class="_20hgQK">{{ $t('cancel') }}</span>
            </a>
          </div>

          <div class="VrgkXA">
            <i class="fal fa-search" />
            <input
              v-model="search"
              autocomplete="off"
              :placeholder="$t('searchInfo')"
              @input="GetOrderAll(status)"
            >
          </div>

          <!-- {{ items }} -->
          <div
            v-for="(item, index) in items"
            :key="index"
            class="hiXKxx"
          >
            <!-- :style="`background-color :${
                item.payNow === 2 ? '#ffe7e7' : '#fff'
              } !important`" -->

            <div class="card">
              <div class="x0QT2k">
                <div class="KrPQEI">
                  <div class="qCUYY8">
                    <div class="_0RxYUS" />
                    <div class="_9Ro5mP">
                      {{ item.itemnumber_text }}<br>
                    </div>
                    <div class="_7wKGws">
                      <button class="stardust-button">
                        <span><i class="fal fa-user-circle" /> {{ item.username }}</span>
                      </button>
                    </div>
                    <div class="yHlRfY">
                      <b-link :to="{name : 'export-detail', params: {id : item._id}}">
                        <button class="stardust-button stardust-button--primary">
                          <span>{{ $t('viewDetails') }}</span>
                        </button>
                      </b-link>
                    </div>
                    <div class="yHlRfY">
                      <feather-icon
                        icon="PrinterIcon"
                        class="text-success cursor-pointer"
                        @click="printpdf(item._id)"
                      />
                    </div>
                  </div>
                  <div class="EQko8g">
                    <div class="qP6Mvo d-none d-md-block">
                      <a
                        class="KmBIg2"
                        href="javascript:void(0)"
                      ><span class="nkmfr2"> {{ $t('orderStatus') }} </span></a>
                      <div
                        id="pc-drawer-id-153"
                        class="anan-drawer"
                        tabindex="0"
                      />
                    </div>
                    <div class="V+w7Xs">
                      <span
                        v-if="item.payNow === 0"
                        class="text-warning"
                      >
                        {{ $t('awaitingPayment') }}
                      </span>
                      <span
                        v-if="item.payNow === 1"
                        class="text-primary"
                      >
                        {{ $t('pendingReview') }}
                      </span>
                      <span
                        v-if="item.payNow === 2"
                      >
                        <span
                          v-if="item.order_out === 1"
                          class="text-danger"
                        >
                          {{ $t('awaitingPickup') }}
                        </span>
                        <span
                          v-if="item.order_out === 2"
                          class="text-success"
                        >
                          {{ $t('receivedItem') }}
                        </span>
                        <span
                          v-if="item.order_thaiout === 1"
                          class="text-info"
                        >
                          {{ $t('awaitingDeliveryTH') }}
                        </span>
                        <span
                          v-if="item.order_thaiout === 2"
                          class="text-success"
                        >
                          {{ $t('key-123') }}
                        </span>
                      </span>

                      <span
                        v-if="item.payNow === 3"
                        class="text-secondary"
                      >
                        {{ $t('cancel') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="FycaKn" />
                <div class="_0OiaZ-">
                  <div class="FbLutl">
                    <div>
                      <span
                        class="x7nENX"
                      >
                        <div class="aybVBK">
                          <div
                            v-if="item.fileimg_th"
                            class="rGP9Yd"
                          >
                            <div

                              class="anan-image__wrapper"
                            >
                              <div class="anan-image__place-holder" />

                              <div
                                class="anan-image__content rounded cursor-pointer"
                                :style="`background-image: url('${GetImg('paymentfile',item.fileimg_th)}');`"
                                @click="showPicture(item.fileimg_th)"
                              >
                                <!-- :style="`background-image: url('${item.shops[0].products[0].picture}');`"
                                @click="showModal(item.shops[0].products[0].picture)" -->
                                <div class="anan-image__content--blur" />
                              </div>
                              <vue-image-lightbox-carousel
                                ref="lightbox"
                                :show="showLightbox"
                                :images="images"
                                :show-caption="false"
                                @close="closeBox()"
                              />
                            </div>
                          </div>
                          <div class="_7uZf6Q">
                            <div>
                              <div class="iJlxsT">
                                <small class="text-muted">{{ $t('deliveryType') }} </small>
                                <div
                                  class="_3F1-5M"
                                  style="margin-top: -5px;"
                                >
                                  {{ item.typedelivery.pro_name }} <small class="text-danger">({{ item.typedelivery.paydelivery }})</small>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="vb0b-P" />
                              <div class="_3F1-5M"><small class="text-muted">{{ $t('shipper') }}</small> {{ item.delivery.name }}</div>
                            </div>
                            <small class="text-warning">{{ $t('creationTime') }} 
                              <small class="text-muted">{{ time(item.created_at) }}</small>
                            <small class="text-success ml-2">วันที่อนุมัติรายการส่งออก <small class="text-muted">{{ item.dateokay ? time(item.dateokay): '-' }}</small></small>
                          </small>
                            
                            
                          </div>
                        </div>
                        <div class="_9UJGhr">
                          <!-- {{ item.shops[0] }} -->
                          <div class="rjqzk1"><span class="-x3Dqh">{{ Commas(item.payment) }} ฿</span></div>
                          <div class="rjqzk1"><span class="-x3Dqh text-danger">{{ Commas(item.Service_fee) }} ฿</span></div>
                        </div>
                      </span>
                    </div>
                    <div class="Cde7Oe" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="GetOrderAll()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <!-- modal -->
          <b-modal
            ref="my-modal"
            hide-footer
            title="รูปภาพสินค้า"
          >
            <img
              :src="pic"
              alt="..."
              class="img-fluid rounded"
            >

            <div class="text-center">
              <button
                type="button"
                class="btn btn-primary mt-2"
                @click="downloadImg"
              >
                Download
              </button>
            </div>
          </b-modal>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination,
  BLink,
  BModal,
  BOverlay,
  // BIconController,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BPagination,
    BLink,
    BModal,
    BOverlay,
    // BIconController,
    // ThisHeader,
    VueImageLightboxCarousel
  },
  data() {
    return {
      show: false,
      showOverlay: false,
      imgList: '',
      search: null,
      isActive: 'all',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      items: [],
      pic: null,

      total: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      images: [],
      showLightbox: false,
    }
  },
  mounted() {
    this.GetNotify()
    this.GetOrderAll(this.status)
  },
  methods: {
    time(data) {
      return moment(data).format('YYYY/MM/DD HH:mm:ss')
    },
    Pay(data) {
      this.$swal({
        title: 'ยืนยันที่จะชำระเงิน ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            ID: data._id,
          }

          this.$http
            .post('/order/confirmpay', params)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              // console.log(response.data)
              this.Success('ชำระเงินสำเร็จ')
              this.show = false
              this.GetNotify()
              this.GetOrderAll()
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
              this.show = false
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    CancelOrder(ID) {
      this.$swal({
        title: 'ยืนยันที่จะยกเลิกรายการ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            ID,
          }
          this.$http
            .post('/order/cancelOrder', params)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              // console.log(response.data)
              this.Success('ยกเลิกรายการสำเร็จ')
              this.show = false
              this.GetNotify()
              this.GetOrderAll()
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
              this.show = false
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    printpdf(data) {
      try {
        // eslint-disable-next-line no-underscore-dangle
        // window.open(`https://api.p2cargo.com/api/print/ExportProductsCusPdf/${data}`)
        window.open(`https://api.p2cargo.com/api/print/ExportProductsCusPdf/${data}`)
      } catch (e) {
        console.log(e)
      }
    },
    async GetOrderAll(type) {
      this.show = true
      try {
        // this.show = true
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          search: this.search,
          type,
        }
        const { data: res } = await this.$http.get('/CustomerPayment/getDataCustomer', { params })
        // console.log(res)
        this.items = res.data
        this.totalRows = res.total
        // console.log(this.items)
        // this.totalRows = res.total
        this.isActive = type
        this.GetNotify()
        this.show = false
      } catch (error) {
        console.log(error)
        this.show = false
      }
    },
    GetOrderDetail() {
      const params = {
        ID: this.$route.params.id,
      }
      this.$http
        .post('/order/OrderById', params)
        .then(response => {
          // console.log(response.data)
          this.details = response.data

          console.log(this.details)
        })
        .catch(error => console.log(error))

      console.log(this.$route.params.id)
    },
    GetNotify() {
      this.$http
        .get('/order/NotifyByCus')
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('paymentfile', picture) }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
  },
}
</script>

  <style lang="scss" scoped></style>
